import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/SEO'
import Skus from '../components/skus'

import '../ui/index.css'

const post = {
  title: "Arti'Mouss - Artisan genevois de bombe de bain",
  description: 'Bombe de bain moussantes aux huiles essentielles bio. Tu aimes les bombes de bain? Tu es au bon endroit !',
  image: 'https://archimouss.ch/assets/images/logo/big.png'
}


const Home = () => {
  return (
    <>
      <Seo title={post.title} description={post.description} image={post.image}/>
      <Layout displayIcons={true} displayBasket={true}>
        <div className='main'>
          <div className='main-content'>
            <div className="main-hero">
              <div className="main-hero-text">
                  <h1>Bombes de bain aux huiles essentielles bio fait main</h1>
                  <p>Pour un moment relaxant, moussant et odorant</p>
                  <div className='cta-container'>
                    <a className="accent-button" href="/#produits">Voir nos produits</a>
                  </div>
                  
              </div>
              <div className="main-hero-image">
                <StaticImage
                    src="../images/images/hero-bath-bomb-2.jpg"
                    alt="bruno de codebruno"
                    placeholder="tracedSVG"
                    layout="constrained"
                  />
              </div>
            </div>
              <div id="produits">
                <h2>Nos produits</h2>
                <Skus />
              </div>
          </div>
        </div>
      </Layout>
    </>

  )
}

export default Home