import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import SkuCard from './sku-card'
import '../ui/skus.css'

const Skus = () => {
    
    return (
        <>
        <StaticQuery
        query={graphql`
            query ProductPrices {
                prices: allStripePrice(
                filter: {active: {eq: true}, product: {images: {ne: "null"}}, currency: {eq: "chf"}}
                sort: {fields: [unit_amount]}
                ) {
                    edges {
                        node {
                        id
                        active
                        currency
                        unit_amount
                            product {
                                id
                                name
                                images
                                metadata {
                                    path
                                }
                            }
                        }
                    }
                }
            }
        `}
        render={({ prices }) => (
        <div className="sku-container">
            {prices.edges.map(({ node: price }) => {
                const newSku = {
                    sku: price.id,
                    name: price.product.name,
                    price: price.unit_amount,
                    currency: price.currency,
                    image: price.product.images,
                    path: price.product.metadata.path,
                }
                
                return <SkuCard key={price.id} sku={newSku} />    
            })}
            { prices.edges.length === 0 ?
            'Aucun produit disponible :(' : null}
        </div>
        
        )}
       
    />
    </>
    )
}

export default Skus


