import { Link } from 'gatsby'
import React, { useState } from 'react'
import { useShoppingCart, formatCurrencyString } from 'use-shopping-cart'

import '../ui/sku-card.css'

const SkuCard = ({ sku }) => {
    const { addItem } = useShoppingCart()

    const [hasAdded, setHasAdded] = useState(false)
    const [nbAdd, setNbAdd] = useState(0)


    function handleAddItem() {
      addItem(sku)
      setHasAdded(true)
      setNbAdd(nbAdd + 1)

    }

    return (
      <div className='container-sku-card'>
        <img src={sku.image} alt={sku.name} />
        <h3>{sku.name}</h3>
        <div class="container-sku-card-info">
          <p>
            {' '}
            {formatCurrencyString({
              value: parseInt(sku.price),
              currency: sku.currency,
            })}
          </p>
          <p className='sku-card-product-added'>{hasAdded ? 'Le produit a été ajouté au panier : ' + nbAdd + 'x' : '' }</p>
        </div>
       
        <div className='container-sku-card-buttons'>
          <Link alt={"voir "  + sku.name} to={"/" + sku.path} className='accent-button'>
            Voir le produit
          </Link>
          <button className='accent-button'  onClick={() => handleAddItem()}>
            Ajouter au panier
          </button>
        </div>
        
      </div>
    )
}

export default SkuCard